import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import LoginHomePage from "./components/LoginHomePage";
import SignupHomePage from "./components/SignupHomePage";
import ChatBox from "./components/ChatBox";
import { ToggleProvider } from "./components/ToggleContext";
import { AuthProvider } from "./components/AuthContext";
// import ForgotPassword from './components/ForgotPassword';
import "./App.css";

function App() {
  return (
    <AuthProvider> {/* Wrap the entire app with AuthProvider */}
      <ToggleProvider>
        <Router>
          <Routes>
            {/* <Route path="/:base" element={<ChatBox />} />
            <Route path="/signin" element={<LoginHomePage />} />
            <Route path="/signin/1" element={<LoginHomePage />} />
            <Route path="/signup" element={<SignupHomePage />} />
            <Route path="/chat/:base" element={<ChatBox />} />*/}
            <Route path="/chat/:base/:chatId" element={<ChatBox />} />
            <Route path="/:base" element={<LoginHomePage />} />
            <Route path="/login" element={<LoginHomePage />} />
            <Route path="/" element={<LoginHomePage />} />
            <Route path="/signup" element={<SignupHomePage />} />
            <Route path="/chat" element={<ChatBox />} />
            <Route path="/" element={<LoginHomePage />} />
          </Routes>
        </Router>
      </ToggleProvider>
    </AuthProvider>
  );
}

export default App;
