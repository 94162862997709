import React, { useState, useRef, useEffect, useContext } from "react";
import { AuthContext } from './AuthContext';
import axios from 'axios';

const ChatInput = ({ onSendMessage, isLoading,message,
  setMessage,
  file,
  setFile,
  fileName,
  setFileName,
  isMicOn,
  setIsMicOn,
  selectedOption,
  setSelectedOption,
  handleSubmit,
  file_present,
  setEvaluationRequested}) => {
   // Prevent multiple submissions
  const speechRecognition = useRef(null);
  const isRecording = useRef(false);
  const timeoutId = useRef(null);
  const textareaRef = useRef(null);
  const [dropdownOptions, setDropdownOptions] = useState([]);
  const maxTextareaHeight = 200; // New state for file name
  const { token } = useContext(AuthContext);
  const [question, setQuestion] = useState('');
  const [answer, setAnswer] = useState('');
  const fileInputRef = useRef(null);


  useEffect(() => {
    const fetchDropdownOptions = async () => {
      try {
        const response = await fetch(
          process.env.REACT_APP_API_BASE_URL + "/languages"
        );
        const data = await response.json();
        setDropdownOptions(data);
        localStorage.setItem("language", "en");
      } catch (error) {
        console.error("Failed to fetch dropdown options:", error);
      }
    };

    fetchDropdownOptions();
  }, []);

  const handleKeyPress = (e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      handleSubmit(e);
    }
  };

  const handleChange = (e) => {
    setMessage(e.target.value);
    handleKeyPress(e);
  };

  

  const startRecording = () => {
    if (isMicOn && "webkitSpeechRecognition" in window) {
      const SpeechRecognition = window.webkitSpeechRecognition;
      speechRecognition.current = new SpeechRecognition();
      speechRecognition.current.continuous = true;
      speechRecognition.current.interimResults = true;
      speechRecognition.current.lang = "en-US";

      speechRecognition.current.onstart = () => {
        isRecording.current = true;
      };

      speechRecognition.current.onresult = (event) => {
        const transcript = Array.from(event.results)
          .map((result) => result[0])
          .map((result) => result.transcript)
          .join("");

        setMessage(transcript);
        restartRecordingAfterPause();
      };

      speechRecognition.current.onerror = (event) => {
        console.error("Speech recognition error", event.error);
      };

      speechRecognition.current.start();
    }
  };

  const restartRecordingAfterPause = () => {
    clearTimeout(timeoutId.current);
    timeoutId.current = setTimeout(() => {
      if (isRecording.current) {
        speechRecognition.current.stop();
        startRecording();
      }
    }, 2000);
  };

  useEffect(() => {
    if (isMicOn) {
      startRecording();
    } else if (speechRecognition.current) {
      speechRecognition.current.stop();
    }
    return () => {
      if (speechRecognition.current) {
        speechRecognition.current.stop();
      }
      clearTimeout(timeoutId.current);
    };
  }, [isMicOn]);

  // const handleFileClick = async(event) => {
    
  //   fileInputRef.current.click();
  //   event.preventDefault();
  //   const formData = new FormData();
  //   formData.append('file', file);
  //   const authToken = 'd71407ac40fa2d6cb92f831af78c7b2466fc2201'

  //   try {
  //     const response = await fetch('http://localhost:5000/upload', {
  //       method: 'POST',
  //       body: formData,
  //       // headers: {
  //       //    'Content-Type': 'multipart/form-data', // Note: Do not set this header; it will be set automatically
  //       // },
  //       headers: {
  //         'Authorization': `Bearer ${authToken}`,
  //         //'Content-Type': 'multipart/form-data', // Include the authentication token
  //       },
  //       mode: 'no-cors',
  //     });

  //     if (!response.ok) {
  //       throw new Error('Network response was not ok');
  //     }

  //     const data = await response.json();
  //     console.log(data);
  //   } catch (error) {
  //     console.error('There was an error uploading the file!', error);
  //   }
  // };
  const handleFileClick = async (event) => {
    setEvaluationRequested(false);
    event.preventDefault();
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };
  

  // const handleFileChange = (e) => {
  //   const file = e.target.files[0];
  //   console.log(file);
  //   //setFile(selectedFile);
  // };
  

  const handleFileChange = async (e) => {
    const file = e.target.files[0];
    const allowedFileTypes = ['txt', 'pdf', 'png', 'jpg', 'jpeg', 'xlsx', 'docx'];
    const fileExtension = file.name.split('.').pop().toLowerCase();
    const maxSize = 20 * 1024 * 1024;
    if (file.size > maxSize) {
      alert('File size exceeds the 20 MB limit.');
      return;
    }
    
    if (!allowedFileTypes.includes(fileExtension)) {
        alert(`Invalid file type. Allowed file types are: ${allowedFileTypes.join(', ')}`);
        setFile(null);
        setFileName("");
        return;
    }

    setFile(file);
    setFileName(file.name);
    onSendMessage('', selectedOption, file.name); 
    await handleSubmit(e);
    const token = localStorage.getItem("authToken");
    
    if (!file) return;

    const formData = new FormData();
    formData.append('file', file);

    try {
        const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/upload`, formData, {
            headers: {
                'Authorization': `Bearer ${token}`,  // Use the token from AuthContext
                'Content-Type': 'multipart/form-data',
            },
        });

        if (response.status !== 200) {
            throw new Error('File upload failed');
        }

        setFile(null);
        setFileName("");
        fileInputRef.current.value = null; // Reset file input
    } catch (error) {
        console.error('There was an error uploading the file!', error);
    }
};

  // const handleFileClick = () => {   
  //   fileInputRef.current.click();
  // };

  // const handleFileChange = (e) => {
  //   const selectedFile = e.target.files[0];
  //   setFile(selectedFile);
  // };

  useEffect(() => {
    adjustTextareaHeight();
  }, [message]);

  const adjustTextareaHeight = () => {
    const textarea = textareaRef.current;
    if (textarea) {
      textarea.style.height = "auto";
      const scrollHeight = textarea.scrollHeight;
      if (scrollHeight > maxTextareaHeight) {
        textarea.style.overflowY = "auto";
        textarea.style.height = maxTextareaHeight + "px";
      } else {
        textarea.style.overflowY = "hidden";
        textarea.style.height = scrollHeight + "px";
      }
    }
  };

  const toggleMic = () => {
    if (isMicOn) {
      setIsMicOn(false);
      if (speechRecognition.current) {
        speechRecognition.current.stop();
        isRecording.current = false;
        // Send the recorded message if it's not empty
        if (message.trim()) {
          onSendMessage(message, selectedOption);
          setMessage("");
        }
      }
    } else {
      // Turning on the microphone
      setIsMicOn(true);
    }
  };

  const handleDropdownChange = (e) => {
    setSelectedOption(e.target.value);
    localStorage.setItem("language", e.target.value);
  };

  return (
    <form onSubmit={handleSubmit} className="form_style">
      <div style={inputContainerStyle}>
        <div style={iconContainerStyle}>
          <button type="button" onClick={toggleMic} style={micButtonStyle}>
            {isMicOn ? (
              <img
                src="/microphone_on.png"
                alt="Mic On"
                style={{ width: "20px", height: "20px" }}
              />
            ) : (
              <img
                src="/microphone_off.png"
                alt="Mic Off"
                style={{ width: "20px", height: "20px" }}
              />
            )}
          </button>

          <button
            type="button"
            onClick={handleFileClick}
            style={attachmentStyle}
            id = "fileUpload"
          >
            <img
              src="/attachment.png"
              alt="Upload"
              style={{ width: "20px", height: "20px"}}
            />
          </button>
          {/* ToDo Uncomment this for language dropdown */}
           {/* <select style={dropdownStyle}  value={selectedOption} onChange={handleDropdownChange}>
            {dropdownOptions.map((option) => (
              <option key={option.code} value={option.code}>
                {option.display_name}
              </option>
            ))}
          //</select>  */}

          <input
            type="file"
            ref={fileInputRef}
            onChange={handleFileChange}
            style={{ display: "none" }}
          />
          <button type="submit" style={sendButtonStyle} disabled={isLoading}>
            {isLoading ? (
              <img
                src="/loading.gif"
                alt="Loading..."
                style={{ width: "20px", height: "20px" }}
              />
            ) : (
              <img
                src="/send_buttton.png"
                alt="Send"
                style={{ width: "20px", height: "20px" }}
              />
            )}
          </button>
        </div>
        <textarea
          ref={textareaRef}
          value={message}
          onChange={handleChange}
          onKeyPress={handleKeyPress}
          placeholder="Ask me anything or you can type here..."
          style={textareaStyle}
        ></textarea>
      </div>
    </form>
  );
};

const formStyle = {
  display: "flex",
  // flexDirection: "column",
  alignItems: "flex-end",
  position: "relative",
  // bottom: "15vh",
  left: "0",
  right: "5%",
  marginLeft: "5rem",
  // marginTop: "32rem",
  marginTop: "19rem",
};

const inputContainerStyle = {
  position: "absolute",
  bottom: 2,
  width: "100%",
  marginLeft: "-3rem",
  display: "flex",
  alignItems: "center", // Align all elements to the center
};

const iconContainerStyle = {
  display: "flex",
  alignItems: "center",
};

const textareaStyle = {
  width: "100%",
  padding: "10px 40px 0px 20px",
  paddingLeft: "20px",
  // paddingLeft: "40px",
  border: "1px solid #ddd",
  borderRadius: "8px",
  resize: "none",
  fontSize: "13px",
  overflow: "hidden",
  fontFamily: 'helvetica-w01-roman, helvetica-w02-roman, helvetica-lt-w10-roman, sans-serif', // Added font family
  fontStyle: 'normal', // Added font style
  fontVariantCaps: 'normal', // Added font variant caps
  fontVariantEastAsian: 'normal', // Added font variant east-asian
  fontVariantLigatures: 'normal', // Added font variant ligatures
  fontVariantNumeric: 'normal', // Added font variant numeric
  fontWeight: '400', // Added font weight
};

const sendButtonStyle = {
  position: "absolute",
  right: "10px",
  bottom: "-6px",
  transform: "translateY(-50%)",
  background: "transparent",
  border: "none",
  cursor: "pointer",
};

const attachmentStyle = {
  position: "absolute",
  right: "90px",
  bottom: "-5px",
  transform: "translateY(-50%)",
  background: "transparent",
  border: "none",
  cursor: "pointer",
};

const micButtonStyle = {
  position: "absolute",
  right: "50px",
  bottom: "-5px",
  transform: "translateY(-50%)",
  background: "transparent",
  border: "none",
  cursor: "pointer",
};

const dropdownStyle = {
  position: "absolute",
  left: "35px", // Adjust this value to position the dropdown next to the attachment button
  bottom: "7px",
  transform: "translateY(-50%)",
  padding: "1px 1px",
  height: "20px",
  // margin: "",
  border: "1px solid #ddd",
  borderRadius: "8px",
  background: "white",
  cursor: "pointer",
  width: "70px",
};

export default ChatInput;
