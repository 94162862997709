// src/components/MainContent.js
import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import ChatInput from "./ChatInput";
import ChatResponse from "./ChatResponse";
import PromptCard from "./PromptCard";
import FollowPromptCard from "./FollowPromptCard";
import getInitials from "./Initials";
import { useParams, useNavigate, Link } from "react-router-dom";
import axios from "axios";
import { InlineWidget } from "react-calendly";
import EmailVerification from "./EmailVerification";

const MainContent = () => {
  const [response, setResponse] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [emailVerification, setEmailVerification] = useState(false);
  const [evaluationRequested, setEvaluationRequested] = useState(false);
  const { base, chatId } = useParams();
  const navigate = useNavigate();
  const messagesEndRef = useRef(null);
  let userName = null;
  const [ans, setAnswer] = useState("");
  const mfileInputRef = useRef(null);
  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState("");
  const [selectedOption, setSelectedOption] = useState("en");
  const [message, setMessage] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isMicOn, setIsMicOn] = useState(false); // New state for microphone status
  var file_present = false;


  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  if (localStorage.getItem("firstName") && localStorage.getItem("lastName")) {
    userName =
      localStorage.getItem("firstName") +
      " " +
      localStorage.getItem("lastName");
  }
  const userInitials = userName ? getInitials(userName) : getInitials("You");

  useEffect(() => {
    scrollToBottom(); // Step 3: Scroll to bottom whenever responses change
  }, [response]);

  const handlePromptClick = (promptText) => {
    handleSendMessage(promptText, localStorage.getItem("language"));
  };

  useEffect(() => {
    const token = localStorage.getItem("authToken");
    setIsLoggedIn(!!token);

    // Function to dynamically load Calendly script
    const loadScript = (src, position, id) => {
      if (!document.getElementById(id)) {
        let script = document.createElement('script');
        script.type = 'text/javascript';
        script.src = src;
        script.id = id;
        script.async = true;
        position.appendChild(script);
      }
    };

    loadScript('https://assets.calendly.com/assets/external/widget.js', document.body, 'calendly-script');
  }, []);

  const handleCalendlyClick = () => {
    if (window.Calendly) {
      console.log("Calendly is defined, opening widget...");
      window.Calendly.initPopupWidget({
        url: 'https://calendly.com/lakshmi-mdxblocksinc/hr-teacher-meeting'
      });
      return false;
    } else {
      console.error('Calendly script not loaded yet');
    }
  };

  const handleSendMessage = async (input, language, fileName = null) => {
    const lastResponse =
      response.length > 0 ? response[response.length - 1] : null;

    if (lastResponse && lastResponse.type === "follow-up-prompts") {
      setResponse((prevArray) => prevArray.slice(0, -1));
    }

    if (fileName) {
      setResponse((prevArray) => [
        ...prevArray,
        {
          text: `Uploaded File: ${fileName}`,
          message: `Uploaded File: ${fileName}`,
          type: "file",
          user: "user",
        },
      ]);
    } else {
      setResponse((prevArray) => [
        ...prevArray,
        {
          text: input,
          message: input,
          type: "question",
          user: "user",
        },
      ]);
    }
    console.log("input   :", input);
    setIsLoading(true); // Start loading
    try {
      const newData = await answer(input, language);
      if (newData) {
        setResponse((prevArray) => [
          ...prevArray,
          {
            text: newData["answer"],
            message: newData["answer"],
            type: "answer",
            user: "bot",
          },
        ]);

        if (newData["prompts"] && newData["prompts"].length) {
          setResponse((prevArray) => [
            ...prevArray,
            {
              prompts: newData["prompts"],
              type: "follow-up-prompts",
              user: null,
            },
          ]);
        } else {
          // const defaultPrompts = await fetchDataFromServer();
          // if (defaultPrompts && defaultPrompts.prompts && defaultPrompts.prompts.length) {
          //   setResponse((prevArray) => [
          //     ...prevArray,
          //     {
          //       prompts: defaultPrompts.prompts,
          //       type: "follow-up-prompts",
          //       user: null,
          //     },
          //   ]);
          // }
        }
      }
    } catch (error) {
      console.error("Error fetching data: ", error);
    } finally {
      setIsLoading(false);
    }
  };

  const answer = async (input, language) => {
    //   try {
    //     const response = await fetch('http://localhost:5000/file_answer', {
    //     method: 'POST',
    //     headers: {
    //       'Content-Type': 'application/json',
    //       Authorization: localStorage.getItem("authToken"),
    //       mode: 'no-cors',
    //     },
    //     body: JSON.stringify({ input }),
    //   });
    //   if (response.ok) {
    //     setAnswer(response.data.ans);
    //     return await response.json();
    //   }

    // } catch (error) {
    //   console.error('Error getting answer:', error);
    // }
    const token = localStorage.getItem("authToken");
    try {
      let queryParam = "base=" + base + "&id=" + chatId;
      const response = await fetch(
        process.env.REACT_APP_API_BASE_URL + "/ask?" + queryParam,
        {
          method: "POST",
          body: JSON.stringify({ question: input, language: language }),
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.ok) {
        return await response.json();
      } else {
        throw new Error("Unable to get the response!");
      }
    } catch (error) {
      console.error("Error in fetching: ", error);
      throw error;
    }
  };

  const fetchDataFromServer = async () => {
    try {
      // const initialData = await answer("Initial question or command to fetch data");
      const token = localStorage.getItem("authToken");
      let queryParam = "base=" + base;
      if (chatId) {
        queryParam = queryParam + "&id=" + chatId;
      }
      const initialData = await fetch(
        process.env.REACT_APP_API_BASE_URL + "/chat?" + queryParam,
        {
          method: "GET",

          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (initialData.ok) {
        let data = await initialData.json();
        if (!chatId && "id" in data) {
          const newUrl = "/chat/" + data["base"] + "/" + data["id"];
          navigate(newUrl);
        }
        return data;
      } else {
        throw new Error("Unable to get the response!");
      }
    } catch (error) {
      console.error("Error fetching initial data: ", error);
    }
  };

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await fetchDataFromServer();
        const key = "prompts";
        let prompts = response[key];

        console.log("response[key]:", response[key]);
        console.log("prompts:", prompts);

        // If prompts is undefined or null, set it to an empty array
        if (!prompts) {
          prompts = [];
        }

        // Convert prompts to an array if it's not already one
        if (!Array.isArray(prompts)) {
          prompts = [prompts];
        }
        if (prompts.length > 0) {
          setResponse([
            {
              prompts: prompts
                .map((item) => {
                  if (Array.isArray(item.prompts)) {
                    return item.prompts;
                  } else {
                    return JSON.stringify(item);
                  }
                })
                .flat(),
              type: "prompt",
              user: null,
            },
          ]);
        } /*
        else {
          setResponse([
            {
              prompts: [
                "Course-Related Questions",
                "Enrollment and Fees",
                "Technical and Support",
                "General Questions",
                "Additional Services",
              ],
              type: "prompt",
              user: null,
            },
          ]);
        }*/
        if (response.chat) {
          setResponse((prevArray) => [...prevArray, ...response["chat"]]);
        }
        if (
          response.followup_prompts &&
          response.followup_prompts.length &&
          response.chat
        ) {
          setResponse((prevArray) => [
            ...prevArray,
            {
              prompts: response.followup_prompts,
              type: "follow-up-prompts",
              user: null,
            },
          ]);
        }
      } catch (error) {
        console.error("Error fetching initial data: ", error);
      }
    }
    fetchData();
  }, []);


  const handleEmailClick = () => {
    setEmailVerification(!emailVerification)
  }


  // Ensure `response` is an array before using .find()
  const meetingMessage = (Array.isArray(response) && response.length > 0)
    ? response[response.length - 1]
    : null;

  // const handleHomeworkEvaluation = Array.isArray(response) 
  // ? response.find(item => item.message === "Use the Upload Button in the Chat Box to upload an Image and get it evaluated") 
  // : null;

  // For .some(), ensure response is an array
  const emailMessage = (Array.isArray(response) && response.length > 0)
    ? response[response.length - 1]
    : null;


  const handleHomeworkEvaluation = Array.isArray(response)
    ? response[response.length - 1]
    : null;


const handleButtonClick = () => {
  // Trigger file input click when the button is clicked
  setEvaluationRequested(true);
  if (mfileInputRef.current) {
    mfileInputRef.current.click();
  }
};


  const homeworkApi = async (base, chatId) => {
    const token = localStorage.getItem("authToken");
    try {
      // Construct the URL with query parameters
      const queryParam = `base=${base}&id=${chatId}`;
      const response = await axios(
        `${process.env.REACT_APP_API_BASE_URL}/hw_eval?${queryParam}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const data = await response.data;
      const newMessage = { message: data.message };
      setResponse(prevResponses => [...prevResponses, newMessage]);
    } catch (error) {
      console.error("Error in fetching: ", error);
      throw error;
    }
  }

const handleHomeworkApi = async (e) => {
  const file = e.target.files[0];
  const allowedFileTypes = ['txt', 'pdf', 'png', 'jpg', 'jpeg', 'xlsx', 'docx'];
  const fileExtension = file.name.split('.').pop().toLowerCase();
  const maxSize = 20 * 1024 * 1024;
  if (file.size > maxSize) {
    alert('File size exceeds the 20 MB limit.');
    return;
  }
  
  if (!allowedFileTypes.includes(fileExtension)) {
      alert(`Invalid file type. Allowed file types are: ${allowedFileTypes.join(', ')}`);
      setFile(null);
      setFileName("");
      return;
    }

    // Display the file submission message before sending
    setResponse(prevResponses => [
      ...prevResponses,
      {
        text: `File submitted: ${file.name}`,
        message: `File submitted: ${file.name}`,
        type: "file",
        user: "user",
      },
    ]);

    const token = localStorage.getItem("authToken");
    const formData = new FormData();
    formData.append('file', file);
    formData.append('call_type', 'homework');

    try {
      const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/upload`, formData, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'multipart/form-data',
        },
      });

      if (response.status !== 200) {
        throw new Error('File upload failed');
      }

      // After successful upload, trigger homework evaluation if requested
      if (evaluationRequested) {
        await homeworkApi(); // Call homework evaluation function
      }

      // Resetting file input and button state
      setFile(null);
      setFileName("");
    } catch (error) {
      console.error('There was an error uploading the file!', error);
    }
    file_present = true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (isSubmitting) return; // Prevent multiple submissions
    setIsSubmitting(true);

    if (file) {
      // No need to call handleSendMessage for file upload, only show file message
      setResponse((prevResponses) => [
        ...prevResponses,
        {
          text: `File submitted: ${file.name}`,
          message: `File submitted: ${file.name}`,
          type: "file",
          user: "user",
        },
      ]);
      setFile(null);
      setFileName("");
    } else {
      if (isMicOn) {
        setIsMicOn(false);
      }
      if (message.trim()) {
        handleSendMessage(message, selectedOption);
        setMessage("");
      }
    }
    setIsSubmitting(false);

  };

  return (
    <MainContainer>
      <div className="MainContent" style={MainContentStyle}>
        <div style={messagesContainerStyle}>
          {Array.isArray(response) && response.map((msg, index) =>
            msg.type === "prompt" ? (
              <div style={promptCardsContainerStyle}>
                {msg.prompts.map((prompt, promptIndex) => (
                  <PromptCard
                    key={promptIndex}
                    text={prompt}
                    size={msg.prompts.length}
                    onClick={() => handlePromptClick(prompt)}
                  />
                ))}
              </div>
            ) : msg.type === "follow-up-prompts" ? (
              <div style={followPromptCardsContainerStyle}>
                {msg.prompts.map((prompt, promptIndex) => (
                  <FollowPromptCard
                    key={promptIndex}
                    text={prompt.display_text}
                    index={promptIndex}
                    onClick={() => handlePromptClick(prompt.prompt)}
                  />
                ))}
              </div>
            ) : msg.user === "user" ? (
              <div>
                <div style={userStyle}>
                  <span style={initialsStyle}>{userInitials}</span>
                  {userName || "You"}
                </div>
                <ChatResponse
                  key={index}
                  text={msg.message}
                  type={msg.type}
                  userType={msg.user}
                />
              </div>
            ) : (
              <div>
                <div style={userStyle_ai}>
                  <img src={"/pulsera.png"} alt={"pulsera"} style={iconStyle} />
                  Pulsera
                </div>
                <ChatResponse
                  key={index}
                  text={msg.message}
                  type={msg.type}
                  userType={msg.user}
                />
              </div>
            )
          )}
          <div ref={messagesEndRef} />
        </div>
        {emailMessage && emailMessage.message === "Send an email using this button" ? (
          <div style={emailbuttonBoxStyle}>
            <button style={emailbuttonStyle} onClick={handleEmailClick}>
              Send Email
            </button>
          </div>
        ) : null}
        <div>
          {meetingMessage && meetingMessage.message === "Schedule a Meeting using the widget button given below" ? (
            <button style={emailbuttonStyle} onClick={handleCalendlyClick}>
              Schedule Meeting with HR
            </button>
          ) : undefined}
        </div>
        {handleHomeworkEvaluation && handleHomeworkEvaluation.message === "Use the Upload Button in the Chat Box to upload an Image and get it evaluated" && (
          <div>
            <input
              type="file"
              ref={mfileInputRef}
              onChange={handleHomeworkApi}
              style={{ display: "none" }}
            />
            <button onClick={handleButtonClick} style={calendlyStyle}>
              Homework Evaluation
            </button>
          </div>
        )}

        <ChatInput onSendMessage={handleSendMessage} isLoading={isLoading} message={message}
          setMessage={setMessage}
          file={file}
          setFile={setFile}
          fileName={fileName}
          setFileName={setFileName}
          isMicOn={isMicOn}
          setIsMicOn={setIsMicOn}
          selectedOption={selectedOption}
          setSelectedOption={setSelectedOption}
          handleSubmit={handleSubmit}
          file_present={file_present}
          setEvaluationRequested={setEvaluationRequested} />
      </div>
      {emailVerification && <EmailVerification emailVerification={emailVerification} setEmailVerification={setEmailVerification} />}
    </MainContainer>
  );
};

const authButtonsContainerStyle = {
  display: "flex",
  justifyContent: "center",
  padding: "10px 20px",
};

const buttonStyle = {
  textDecoration: "none",
  color: "#fff",
  backgroundColor: "#007bff",
  padding: "10px 25px",
  margin: "0 10px",
  borderRadius: "5px",
};

const MainContainer = styled.div`
  @media (max-width: 900px) {
    margin-bottom: 30px;
  }
  @media (max-width: 500px) {
    margin-bottom: 40px;
  }
`;

const MainContentStyle = {
  display: "flex",
  flexDirection: "column",
  height: "90vh",
  // height: "100%",
  // border: "2px solid gray",
  // boxShadow: "0 2rem 4rem #000",
  width: "75vw",
  overflow: "hidden",
};

const followPromptCardsContainerStyle = {
  justifyContent: "space-around",
  flexWrap: "wrap",
  alignItems: "left",
  margin: "10px, 0",
};

const promptCardsContainerStyle = {
  display: "flex",
  overflowY: "auto",
  justifyContent: "space-around",
  flexWrap: "wrap",
};

const messagesContainerStyle = {
  overflowY: "auto",
  flexDirection: "column",
  flex: 1,
  padding: "10px",
  margin: "10px 0",
};
const userStyle_ai = {
  fontWeight: "bold",
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-start", // Align user's message to the right
  marginBottom: "10px",
};

const userStyle = {
  fontWeight: "bold",
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end", // Align user's message to the right
  marginBottom: "10px",
};

const initialsStyle = {
  display: "inline-block",
  marginRight: "8px",
  backgroundColor: "#bbd9fc",
  color: "#fff",
  borderRadius: "50%",
  width: "24px",
  height: "24px",
  textAlign: "center",
  lineHeight: "24px",
  fontWeight: "normal",
};

const iconStyle = {
  display: "inline-block",
  marginRight: "8px",
  width: "24px",
  height: "24px",
  borderRadius: "50%",
  backgroundColor: "#bbd9fc",
};

const calendlyStyle = {
  width: "40%",
  height: "40px",
  margin: "15px 15px 0px 15px",
  border: "2px solid #234bd6",
  borderRadius: "8px",
  fontSize: "20px",
  backgroundColor: "#934bd6",
  color: "white",
  fontWeight: "bold",
  cursor: "pointer"
};


const emailbuttonBoxStyle = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
}

const emailbuttonStyle = {
  marginTop: "20px",
  padding: "7px 20px",
  backgroundColor: "#007bff",
  color: "#fff",
  border: "none",
  borderRadius: "5px",
  cursor: "pointer",
  fontSize: "20px",
  width: "600px",
};

export default MainContent;
